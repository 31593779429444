import actions from '../actions/environment.action'

const defaultState = {
    isLinkedinTabOpen: true,
    api_url: "http://localhost:5000/api",
    socket_url: "http://localhost:5000/",
    maintenance: false,
    accountNumber: 0,
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.IS_LINKEDIN_TAB_OPEN:
            return {
                ...state,
                isLinkedinTabOpen: action.payload
            };
        case actions.ACCOUNTS_NUMBER:
            return {
                ...state,
                accountNumber: action.payload,
            };
        case actions.MAINTENANCE:
            return {
                ...state,
                maintenance: action.payload
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}