import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import 'semantic-ui-css/semantic.min.css'
import 'semantic-ui-less/semantic.less';
import './i18n';
import { Provider } from 'react-redux';
import store from './redux/configureStore'
import axios from 'axios'
import ErrorBoundaries from './ErrorBoundaries'

(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/nt4il1tw'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();

if (store.isProxy) {
    store.entity.ready().then(() => {

        axios.interceptors.response.use(function (response) {
            return response
        }, function (error) {
            if (error.response && error.response.status === 402) {
                store.entity.dispatch({ type: "CHANGE_TAB", payload: 0 })
                store.entity.dispatch({ type: "CHANGE_LOCATION", payload: { tab: "/upgrade", params: { reason: error.response.data.reason } } })
                store.entity.dispatch({ type: "ACCOUNT_TYPE", payload: { accountType: error.response.data.message.currentRank } })
            }
            else if (error.response && error.response.status === 503 && error.response.data.toLowerCase().includes("maintenance")) {
                store.entity.dispatch({ type: "MAINTENANCE", payload: true })
            }
            return Promise.reject(error)
        });
        // The store implements the same interface as Redux's store
        // so you can use tools like `react-redux` no problem!
        ReactDOM.render(<ErrorBoundaries>
            <Provider store={store.entity}>
                <App />
            </Provider>
        </ErrorBoundaries>, document.getElementById('root'));
    })
}
else {

    axios.interceptors.response.use(function (response) {
        return response
    }, function (error) {
        if (error.response && error.response.status === 402) {
            store.entity.dispatch({ type: "CHANGE_TAB", payload: 0 })
            store.entity.dispatch({ type: "CHANGE_LOCATION", payload: { tab: "/upgrade", params: { reason: error.response.data.reason } } })
            store.entity.dispatch({ type: "ACCOUNT_TYPE", payload: { accountType: error.response.data.message.currentRank } })
        }
        else if (error.response && error.response.status === 503 && error.response.data.toLowerCase().includes("maintenance")) {
            store.entity.dispatch({ type: "MAINTENANCE", payload: true })
        }
        return Promise.reject(error)
    });

    ReactDOM.render(<ErrorBoundaries>
        <Provider store={store.entity}>
            <App />
        </Provider>
    </ErrorBoundaries>, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
