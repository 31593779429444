export default {
    "FETCH_QUEUE": "FETCH_QUEUE",
    "FETCH_SUBSEQUENT_QUEUE": "FETCH_SUBSEQUENT_QUEUE",
    "DELETED_ACTION": "DELETED_ACTION",
    "CREATED_ACTION": "CREATED_ACTION",
    "REST_MODE_UPDATE": "REST_MODE_UPDATE",
    "SELECTED_ACTIONS": "SELECTED_ACTIONS",
    "DELETED_ALL_ACTION": "DELETED_ALL_ACTION",
    "FETCHED_QUOTA": "FETCHED_QUOTA",
    "INCREMENT_QUOTA": "INCREMENT_QUOTA",
    "DECREMENT_QUOTA": "DECREMENT_QUOTA",
    "FETCH_ACTION_BY_TYPE_COUNT": "FETCH_ACTION_BY_TYPE_COUNT"
}