import { combineReducers } from 'redux';
import campaigns from './campaign.reducer';
import notifications from './notification.reducer';
import router from './router.reducer';
import queue from './queue.reducer';
import extracter from './extracter.reducer';
import overview from './overview.reducer';
import dashboard from './dashboard.reducer';
import prospects from './prospects.reducer';
import layouts from './layout.reducer';
import tags from './tag.reducer';
import faq from './faq.reducer';
import funnels from './Funnel';
import Company from './Company';
import Trigger from './Trigger';
import quests from './quests.reducer';
import review from './review.reducer'

export default () => combineReducers({
    campaigns,
    notifications,
    queue,
    router,
    extracter,
    overview,
    dashboard,
    prospects,
    layouts,
    faq,
    quests,
    review,
    tags: tags,
    funnels: funnels(),
    company: Company(),
    trigger: Trigger()
});