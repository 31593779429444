/* global chrome */
import React, { Component, useEffect } from "react";
import { I18nextProvider, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import "./App.css";
import LoginScreen from "./components/App/LoginScreen";
import { EXTENSION_ID } from "./constants";
import NotificationScreen from "./containers/NotificationScreen.container";
import { setupMessageListener } from "./helpers/listenMessage";
import i18n from "./i18n";
import {
  getActiveAccount,
  getCrmState,
  getEnvironment,
  getQuest,
  getRootAccount,
} from "./redux/selectors";
import UserServices from "./services/UserServices";
import { identify, page } from "./utils/analytics";

const LoadingScreen = (props) => {
  return (
    <div style={{ height: "100vh", display: "flex", width: "100%" }}>
      <Dimmer active inverted>
        <Loader
          size="massive"
          inverted
          content={props.textToDisplay}
          disabled={props.noLoader}
        />
        {props.noLoader && (
          <h2 style={{ color: "black" }}>{props.textToDisplay} </h2>
        )}
      </Dimmer>
      <div
        style={{
          position: "absolute",
          background: `url(background.jpeg) no-repeat center center fixed`,
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          filter: "blur(1.5rem)",
        }}
      />
    </div>
  );
};

const mapStateToPropsNotifier = (state) => {
  return {
    environment: getRootAccount(state),
    accountNumber: getEnvironment(state) && getEnvironment(state).accountNumber,
    tab: getCrmState(state).router.tab,
    hash: getCrmState(state).router.params.chat || null,
    overview: getCrmState(state).overview,
    currentTab:
      getCrmState(state).campaigns &&
      getCrmState(state).campaigns.selected &&
      getCrmState(state).campaigns.selected.tabSelected,
  };
};

const mapDispatchToPropsNotifier = (dispatch) => {
  return {};
};

const IntercomNotifier = connect(
  mapStateToPropsNotifier,
  mapDispatchToPropsNotifier
)(
  ({
    environment: {
      linkedin_profile: { email, firstName, lastName },
      account_type,
      origin,
      freeTrialExpiration,
      paymentExpiration,
    },
    currentTab,
    accountNumber,
    tab,
    hash,
    overview: { prospects },
  }) => {
    useEffect(() => {
      (async () => {
        window.Intercom("update", {
          app_id: "nt4il1tw",
          hide_default_launcher: true,
          email: email,
          firstName: firstName,
          lastName: lastName,
          currentPage: tab,
          currentTab: currentTab,
          prospects: prospects,
          accountType: account_type,
          expirationDate: freeTrialExpiration || paymentExpiration,
          multicompte: accountNumber,
          origin: origin,
          tutorialFinished:
            chrome && chrome.runtime && chrome.runtime.sendMessage
              ? await new Promise((resolve) =>
                  chrome.runtime.sendMessage(
                    EXTENSION_ID,
                    {
                      action: "storage",
                      verb: "GET",
                      params: ["tutorial_stage"],
                    },
                    (result) => {
                      resolve(
                        result.tutorial_stage &&
                          result.tutorial_stage === "success"
                          ? 1
                          : 0
                      );
                    }
                  )
                )
              : -1,
        });
      })();
    }, [
      email,
      firstName,
      lastName,
      tab,
      hash,
      prospects,
      currentTab,
      account_type,
      freeTrialExpiration,
      paymentExpiration,
      accountNumber,
      origin,
    ]);
    return <></>;
  }
);

class Wrapper extends Component {
  state = {
    fetched: false,
    googleTranslationActive: false,
    openUpgradeModal: false,
    canStartQuest: false,
  };

  async componentDidMount() {
    setInterval(() => {
      if (document.getElementsByClassName("translated-ltr").length > 0) {
        if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
          chrome.runtime.sendMessage(
            EXTENSION_ID,
            {
              action: "storage",
              verb: "GET",
              params: ["googleTranslationWarning"],
            },
            (res) => {
              if (!res.googleTranslationWarning) {
                this.setState({ googleTranslationActive: true });
              }
            }
          );
        }
      }
    }, 5000);

    setupMessageListener();

    if (this.props.api_token) {
      this.props.setMaintenance();
      await this.props.fetchOverview();
      await this.props.getAccountType();
      this.setState({ fetched: true });
      const jwt = require("jwt-simple");
      this.props.getUserWaapiId().then((res) => {
        const linkedinId = jwt.decode(this.props.api_token, "", true)
          .linkedinId;
        identify(String(res.waapiIdClient), {
          linkedinId: linkedinId,
          firstName: this.props.environment.linkedin_profile.firstName,
          lastName: this.props.environment.linkedin_profile.lastName,
          occupation: this.props.environment.linkedin_profile.infos.occupation,
          email: this.props.environment.linkedin_profile.email,
          language: this.props.environment.language,
          accountType: this.props.environment.account_type,
        });
      });
    }
    this.props.getUserCanStartQuest().then((res) => {
      this.setState({ canStartQuest: res.canStartQuest });
    });
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("p")) {
      this.props.changeTab(`/${urlParams.get("p")}`);
    }
    if (this.props.hash) {
      window.location.hash = this.props.hash;
    }

    page({
      title: document.title,
      url: `${window.location.pathname}${window.location.search}${window.location.hash}`,
    });
  }

  render() {
    const { t } = this.props;
    if (!this.props.isLinkedinTabOpen)
      return <LoadingScreen textToDisplay={i18n.t("no_linkedin_tab_opened")} />;
    else if (this.props.maintenance)
      return (
        <LoadingScreen
          textToDisplay={i18n.t("api_maintenance")}
          noLoader={true}
        />
      );
    else if (this.props.linkedin_profile === null || !this.props.api_token)
      return <LoadingScreen textToDisplay={i18n.t("please_log_in")} />;
    else if (this.props.isPasswordAuthenticated === false)
      return <LoginScreen />;
    else
      return (
        <I18nextProvider i18n={i18n}>
          <div className="App">
            <NotificationScreen>
              {this.state.fetched && <IntercomNotifier />}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "space-around",
                  height: "100vh",
                }}
              >
                <h1 style={{ color: "#00BA88" }}>
                  {t("prospectin_end_header")}
                </h1>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <img src="Prospectin Logo 2.png" />
                  <img src="Vector.png" />
                  <img src="Blue-flat.png" />
                </div>
                <p style={{ fontWeight: "bold" }}>
                  {t("prospectin_end_description")}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      flexDirection: "column",
                    }}
                  >
                    <h2 style={{ fontWeight: "bold" }}>{t("waalaxy_title")}</h2>
                    <div>{t("waalaxy_description")}</div>
                    <div style={{ display: "flex" }}>
                      <ul>
                        <li style={{ color: "#4E4B66", fontWeight: "700" }}>
                          {t("waalaxy_advantage_1")}
                        </li>
                        <li style={{ color: "#4E4B66", fontWeight: "700" }}>
                          {t("waalaxy_advantage_2")}
                        </li>
                        <li style={{ color: "#4E4B66", fontWeight: "700" }}>
                          {t("waalaxy_advantage_3")}
                        </li>
                        <li style={{ color: "#4E4B66", fontWeight: "700" }}>
                          {t("waalaxy_advantage_4")}
                        </li>
                      </ul>

                      <ul>
                        <li style={{ color: "#4E4B66", fontWeight: "700" }}>
                          {t("waalaxy_advantage_5")}
                        </li>
                        <li style={{ color: "#4E4B66", fontWeight: "700" }}>
                          {t("waalaxy_advantage_6")}
                        </li>
                        <li style={{ color: "#4E4B66", fontWeight: "700" }}>
                          {t("waalaxy_advantage_7")}
                        </li>
                        <li style={{ color: "#4E4B66", fontWeight: "700" }}>
                          {t("waalaxy_advantage_8")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <img src="Frame.png" />
                    <p
                      style={{
                        color: "#6E7191",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        window.open(
                          "https://www.youtube.com/watch?v=MdJS93_tjGQ&list=PLD7QMPI8zqoHHy1tfj8MAMDhModkqNugb&ab_channel=Waalaxy-LeadGenerationLinkedIn%2BEmail",
                          "_blank"
                        );
                      }}
                    >
                      {t("waalaxy_know_more")}
                    </p>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      window.open(
                        "https://chrome.google.com/webstore/detail/waalaxy-ex-prospectin-pro/hlkiignknimkfafapmgpbnbnmkajgljh?hl=fr",
                        "_blank"
                      );
                    }}
                    color="green"
                  >
                    {t("waalaxy_button")}
                  </Button>
                </div>
                <p>{t("waalaxy_transfer_explanation")}</p>
                <p
                  style={{
                    color: "#6E7191",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={async () => {
                    try {
                      const { url } = await UserServices.unsubscribe();
                      window.location = url;
                    } catch (error) {
                      console.log(error);
                      return false;
                    }
                  }}
                >
                  {t("unsubscribe")}
                </p>
              </div>
            </NotificationScreen>
          </div>
        </I18nextProvider>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    isLinkedinTabOpen: getEnvironment(state).isLinkedinTabOpen,
    isPasswordAuthenticated: getRootAccount(state).isPasswordAuthenticated,
    shouldUpdateBillingInformations: getActiveAccount(state)
      .shouldUpdateBillingInformations,
    api_token: getActiveAccount(state).api_token,
    maintenance: getEnvironment(state).maintenance,
    linkedin_profile: getActiveAccount(state).linkedin_profile,
    tab: getCrmState(state).router.tab,
    quests: getQuest(state),
    accountType: getActiveAccount(state).account_type,
    environment: getRootAccount(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMaintenance() {
      dispatch({ type: "MAINTENANCE", payload: false });
    },
    changeTab(tab) {
      dispatch({ type: "CHANGE_LOCATION", payload: { tab } });
    },
    async fetchOverview() {
      try {
        const overview = await UserServices.fetchOverview();
        dispatch({ type: "FETCH_OVERVIEW", payload: overview });
        dispatch({
          type: "HAS_EXECUTED_ACTION",
          payload: overview.hasExecutedAnyAction,
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async getAccountType() {
      try {
        const result = await UserServices.getAccountType();
        dispatch({ type: "ACCOUNT_TYPE", payload: result.accountType });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async getShouldUpdateBillingInfo() {
      try {
        const result = await UserServices.shouldUpdateBillingInformation();
        dispatch({
          type: "SHOULD_UPDATE_BILLING_INFORMATION",
          payload: result.shouldUpdateBillingInformation,
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async updatedBillingInformations() {
      try {
        dispatch({ type: "SHOULD_UPDATE_BILLING_INFORMATION", payload: false });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async getBillingInformation() {
      try {
        return await UserServices.getBillingInformation();
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async updateBillingInformation(body) {
      try {
        await UserServices.updateBillingInformation(body);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async getUserCanStartQuest() {
      try {
        return await UserServices.getUserCanStartQuest();
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async getUserWaapiId() {
      try {
        return await UserServices.getUserWaapiId();
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Wrapper)
);
