import io from 'socket.io-client';
import { getSocketUrl } from "./redux/selectors"

let socket

export default {
    init(store) {
        return new Promise(r => {
            socket = io(getSocketUrl(store.getState()), { reconnectionDelay: 5000, transports: ['websocket'] })

            socket.on("connect", () => {
                console.log("Connected !")
                r()
            })
            socket.on("action", (action) => store.dispatch(action))

            socket.on("error", (error) => { console.log(error) })

            socket.on("disconnect", () => console.log("Disconnected"))
        })
    },
    emit(type, payload) {
        socket.emit(type, payload)
    },
    subscribe(channel) {
        socket.emit("subscribe", channel)
    },
    unsubscribe(channel) {
        socket.emit("unsubscribe", channel)
    },
    isInitialyzed() {
        return !!socket
    }
}