import plugin from '@analytics/segment';

export const CONFIG = {
  writeKey: 'WPRQmSj842ChDtUuyygNlQ7HfhTXLHto',
};

export const segmentPlugin = plugin(CONFIG);
export const config = {
  name: 'segment',
};
