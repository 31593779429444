/* global chrome */
import React from "react";
import { Button, Dimmer, Popup } from "semantic-ui-react";
import StackTrace from "stacktrace-js";
import { EXTENSION_ID } from "./constants";
import i18n from "./i18n";
import ContactService from "./services/ContactService";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== "development") {
      StackTrace.fromError(error).then((parsed) => {
        ContactService.internalError(
          JSON.stringify({
            errorMessage: error.message,
            stack: parsed.slice(0, 3),
          })
        );
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ height: "100vh", display: "flex", width: "100%" }}>
          <Dimmer active inverted>
            <h1 style={{ color: "black" }}>{i18n.t("unexpected_error")}</h1>
            <Popup
              content={i18n.t("reload_tooltip")}
              trigger={
                <Button
                  color="green"
                  style={{ width: "20%" }}
                  content={i18n.t("reload")}
                  onClick={() => {
                    if (
                      chrome &&
                      chrome.runtime &&
                      chrome.runtime.sendMessage
                    ) {
                      chrome.runtime.sendMessage(
                        EXTENSION_ID,
                        {
                          action: "storage",
                          verb: "SET",
                          params: { should_open_crm: true },
                        },
                        () => window.location.reload()
                      );
                      chrome.runtime.sendMessage(
                        EXTENSION_ID,
                        {
                          action: "reload",
                        },
                        () => window.location.reload()
                      );
                    }
                  }}
                />
              }
            />
          </Dimmer>
          <div
            style={{
              position: "absolute",
              background: `url(background.jpeg) no-repeat center center fixed`,
              backgroundSize: "cover",
              width: "100%",
              height: "100vh",
              filter: "blur(1.5rem)",
            }}
          />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
