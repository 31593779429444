const defaultState = {
    last_report: {
        isExternal: false,
        campaignId: null,
        scanned: 0,
        exported: 0,
        errors: 0,
        alreadyExisting: 0,
        pagesVisited: 0,
        reachedEnd: false,
        reached429: false,
        numberOfPropects: 0,
        was_tutorial: false
    }
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case "EXTRACTING_REPORT":
            return {
                ...state,
                last_report: action.payload
            }
        case "REPORT_READ":
            return {
                ...state,
                last_report: {
                    isExternal: false,
                    campaignId: null,
                    scanned: 0,
                    exported: 0,
                    errors: 0,
                    alreadyExisting: 0,
                    pagesVisited: 0,
                    reachedEnd: false,
                    reached429: false,
                    numberOfPropects: 0,
                    was_tutorial: false
                }
            }
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}