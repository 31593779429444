export default {
    "ACTIVITY_SELECTED_CAMPAIGN": "ACTIVITY_SELECTED_CAMPAIGN",
    "ACTIVITY_SELECTED_NOTE": "ACTIVITY_SELECTED_NOTE",
    "ACTIVITY_TOTAL_PAGES": "ACTIVITY_TOTAL_PAGES",
    "ACTIVITY_SELECTED_MESSAGE": "ACTIVITY_SELECTED_MESSAGE",
    "ACTIVITY_FETCHED_NOTE": "ACTIVITY_FETCHED_NOTE",
    "ACTIVITY_FETCHED_MESSAGE": "ACTIVITY_FETCHED_MESSAGE",
    "ACTIVITY_TAB": "ACTIVITY_TAB",
    "ACTIVITY_PLOT_DATA": "ACTIVITY_PLOT_DATA",
    "ACTIVITY_SELECTED_FORMAT": "ACTIVITY_SELECTED_FORMAT",
    "ACTIVITY_CHANGE_PAGE": "ACTIVITY_CHANGE_PAGE",
    "GENERAL_STATS": "GENERAL_STATS",
    "PERFORMANCES_DATA": "PERFORMANCES_DATA",
    "PERFORMANCE_SELECTED_CAMPAIGN": "PERFORMANCE_SELECTED_CAMPAIGN",
    "PERFORMANCE_SELECTED_TIMEFRAME": "PERFORMANCE_SELECTED_TIMEFRAME"
}