import actions from '../../actions/environment.action';
import queueActions from '../../actions/queue.action';
import socket from '../../../socket';

const defaultState = {
    api_token: null,
    language: 'en',
    linkedin_profile: null,
    account_type: 1,
    paymentExpiration: false,
    freeTrialExpiration: false,
    subscriptionType: false,
    subscriptionActive: false,
    isStaff: false,
    activityReport: {
        activeDays: [2, 6, 0],
        hours: {
            '1977-04-22T06:00:00Z': false,
            '1977-04-22T12:00:00Z': true,
            '1977-04-22T18:00:00Z': false,
            '1977-04-22T00:00:00Z': false,
        },
    },
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.CONNECTED_AS:
            socket.subscribe({ channelId: action.payload.profile.linkedinId, token: action.payload.token, type: 'user', guess: true });
            return {
                ...state,
                api_token: action.payload.token,
                linkedin_profile: action.payload.profile,
                isStaff: action.payload.isStaff,
            };
        case queueActions.REST_MODE_UPDATE:
            return {
                ...state,
                rest_mode: action.payload,
            };
        case actions.SECONDARY_ACCOUNT_TYPE:
            return {
                ...state,
                account_type: action.payload.accountType,
                paymentExpiration: action.payload.paymentExpiration,
                freeTrialExpiration: action.payload.freeTrialExpiration,
                subscriptionType: action.payload.subscriptionType,
            };
        case actions.DISCONNECTED_FROM:
            socket.unsubscribe({ channelId: state.linkedin_profile && state.linkedin_profile.linkedinId, token: state.api_token, type: 'user' });
            return defaultState;
        case actions.FETCHED_PERSONAL_ACTIVITY_REPORT_SETTINGS:
            return {
                ...state,
                activityReport: action.payload,
            };
        case 'DISCONNECTED_FROM_LINKEDIN':
            return defaultState;
        default:
            return state;
    }
};
