import axios from "axios";
import { tokenHeader, apiUrl } from "../helpers/ApiHelper"

export default {
    async sendMessage(subject, message) {
        try {
            const header = tokenHeader()
            if (!header)
                return false
            const res = await axios.post(`${apiUrl()}/contact`, { subject, message }, header)
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    },
    async internalError(message) {
        try {
            const header = tokenHeader()
            if (!header)
                return false
            const res = await axios.post(`${apiUrl()}/clientError`, { message }, header)
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}