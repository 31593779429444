export default {
    "CHANGE_COMPANY_TAB": "CHANGE_COMPANY_TAB",
    "FETCHED_ACTIVITY_REPORT_SETTINGS": "FETCHED_ACTIVITY_REPORT_SETTINGS",
    "FETCHED_DUPLICATES": "FETCHED_DUPLICATES",
    "FETCHED_MEMBERS": "FETCHED_MEMBERS",
    "FETCHED_MAX_USERS": "FETCHED_MAX_USERS",

    "UPDATED_MEMBER_PERMISSIONS": "UPDATED_MEMBER_PERMISSIONS",
    "DELETED_MEMBER": "DELETED_MEMBER",

    "INVITATION_RECEIVED": "INVITATION_RECEIVED",
    "INVITATION_ACCEPTED": "INVITATION_ACCEPTED",
    "INVITATION_DECLINED": "INVITATION_DECLINED",
    "INVITATION_SENT": "INVITATION_SENT",

    "DELETED_DUPLICATE": "DELETED_DUPLICATE",
    "FETCHED_MORE_DUPLICATES": "FETCHED_MORE_DUPLICATES",
    "UPDATED_DUPLICATE": "UPDATED_DUPLICATE",
    "CREATED_DUPLICATE": "CREATED_DUPLICATE",

    "FETCHED_COMPANY": "FETCHED_COMPANY",
    "ACCEPT_INVITATION": "ACCEPT_INVITATION",
    "DECLINE_INVITATION": "DECLINE_INVITATION",
    "CAN_USE_FREE_TRIAL": "CAN_USE_FREE_TRIAL",
    "FETCHED_CURRENT_MEMBER": "FETCHED_CURRENT_MEMBER",

    "UPDATE_COMPANY_DASHBOARD": "UPDATE_COMPANY_DASHBOARD",
    "COMPANY_GENERAL_STATS": "COMPANY_GENERAL_STATS",
    "COMPANY_ACTIVITY_SELECTED_CAMPAIGN": "COMPANY_ACTIVITY_SELECTED_CAMPAIGN",
    "COMPANY_ACTIVITY_SELECTED_NOTE": "COMPANY_ACTIVITY_SELECTED_NOTE",
    "COMPANY_ACTIVITY_SELECTED_MESSAGE": "COMPANY_ACTIVITY_SELECTED_MESSAGE",
    "COMPANY_ACTIVITY_TAB": "COMPANY_ACTIVITY_TAB",
    "COMPANY_ACTIVITY_SELECTED_FORMAT": "COMPANY_ACTIVITY_SELECTED_FORMAT",
    "COMPANY_ACTIVITY_PLOT_DATA": "COMPANY_ACTIVITY_PLOT_DATA",
    "COMPANY_ACTIVITY_TOTAL_PAGES": "COMPANY_ACTIVITY_TOTAL_PAGES",
    "COMPANY_ACTIVITY_CHANGE_PAGE": "COMPANY_ACTIVITY_CHANGE_PAGE",
    "COMPANY_ACTIVITY_FETCHED_NOTE": "COMPANY_ACTIVITY_FETCHED_NOTE",
    "COMPANY_ACTIVITY_FETCHED_MESSAGE": "COMPANY_ACTIVITY_FETCHED_MESSAGE",
    "COMPANY_PERFORMANCES_DATA": "COMPANY_PERFORMANCES_DATA",
    "COMPANY_PERFORMANCE_SELECTED_CAMPAIGN": "COMPANY_PERFORMANCE_SELECTED_CAMPAIGN",
    "COMPANY_PERFORMANCE_SELECTED_TIMEFRAME": "COMPANY_PERFORMANCE_SELECTED_TIMEFRAME",
    "COMPANY_DASHBOARD_FETCHED_CAMPAIGNS": "COMPANY_DASHBOARD_FETCHED_CAMPAIGNS",

    "FETCHED_FEED": "FETCHED_FEED",
    "RESET_FEED": "RESET_FEED",
    "MEMBER_DISCONNECTED": "MEMBER_DISCONNECTED",
    "MEMBER_CONNECTED": "MEMBER_CONNECTED",
    "KICKED_FROM_COMPANY": "KICKED_FROM_COMPANY",
    "FETCHED_MORE_MEMBERS": "FETCHED_MORE_MEMBERS"
}