import actions from "../../actions/tag.action"

const defaultState = {
    all: []
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.FETCHED_TAGS:
            return {
                ...state,
                all: action.payload
            };
        case actions.CREATED_TAG:
            return {
                ...state,
                all: [...state.all, action.payload.entity]
            };
        case actions.DELETED_TAG:
            return {
                ...state,
                all: state.all.filter(tag => tag._id !== action.payload.entity._id)
            };
        case actions.UPDATED_TAG:
            return {
                ...state,
                all: state.all.map(tag => tag._id === action.payload.entity._id ? { ...tag, ...action.payload.entity } : tag)
            };
        default:
            return state
    }
}