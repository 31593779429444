import actions from "../../actions/faq.action"

const defaultState = {
    all: [],
    opened: {}
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.FETCH_QUESTION:
            return {
                ...state,
                all: action.payload
            };
        case actions.QUESTION_OPENED:
            return {
                ...state,
                opened: action.payload
            }
        case actions.QUESTION_CLOSED:
            return {
                ...state,
                opened: {}
            }
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}