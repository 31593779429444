import { createStore } from "redux";
import { applyMiddleware, Store } from "webext-redux";
import patchDeepDiff from "webext-redux/lib/strategies/deepDiff/patch";
import { EXTENSION_ID } from "../constants";
import socket from "../socket";
import rootReducer from "./reducers/root.reducer";
import { track } from '../utils/analytics';

let store;

const initStore = (initialState = {}) => {
  const store = createStore(
    rootReducer(),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  socket.init(store).then(() => socket.emit("subscribe", "626531871"));
  return store;
};
try {
  const proxyStore = new Store({
    patchStrategy: patchDeepDiff,
    extensionId: EXTENSION_ID,
  })

  store = {
    entity: applyMiddleware(proxyStore, ({ getState }) => {
      return next => action => {
        if(action.type === "CHANGE_LOCATION"){
          window.history.pushState("","",action.payload.tab)
        }
        if(action.type === "CHANGE_TAB"){
          console.log(action)
          const array = ["visit", "follow", "connect", "message", "funnel"]
          window.history.pushState("","","/campaign/" + array[action.payload])
        }
        return next(action)
      }
    }),
    isProxy: true,
  };
} catch (error) {
  console.log(error);
  store = { entity: initStore(), isProxy: false };
}

export default store;
