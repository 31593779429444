import actions from '../../actions/environment.action';
import queueActions from '../../actions/queue.action';

const defaultState = {
    api_token:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1ZTkyNDkyMWM2YjA4YzE3OWYyYjRlMGEiLCJsaW5rZWRpbklkIjoiNzU3MjIxOTYwIn0.BJmkyd4pOaMDvs2_maQC9vAExJJBp5SDvyMalKiKbMc',
    language: 'en',
    linkedin_profile: {
        firstName: 'Kevin',
        lastName: 'Giordani',
        profilePicture:
            'https://media.licdn.com/dms/image/C5603AQH7gokfpDz4Jg/profile-displayphoto-shrink_800_800/0?e=1580342400&v=beta&t=XnVmgnekWRq-dt0RrSqROGz49boGN-atyB_iWxGrDr8',
        email: 'giordani.kevin.02@gmail.com',
        infos: {
            entityUrn: 'urn:li:fs_miniProfile:ACoAACVYHh8BNaI2fdyotqr8LLDzIylPKuXFYJQ',
            occupation: 'Software Development Engineer chez ProspectIn',
            publicIdentifier: 'kevin-giordani-3939a8155',
            trackingId: 'Y8k6Ry8RSoyH9ZG0rAzeNA==',
        },
    },
    account_type: 1,
    paymentExpiration: false,
    freeTrialExpiration: false,
    subscriptionType: false,
    subscriptionActive: false,
    origin: '',
    email: '',
    rest_mode: { enabled: true, activeDays: [1, 2, 3, 4, 5, 6, 0], from: 6, to: 22 },
    activityReport: {
        activeDays: [2, 6, 0],
        hours: {
            '1977-04-22T06:00:00Z': false,
            '1977-04-22T12:00:00Z': true,
            '1977-04-22T18:00:00Z': false,
            '1977-04-22T00:00:00Z': false,
        },
    },
    isStaff: true,
    emptyQueueNotification: false,
    tutorialNotification: false,

    referralLink: '',
    referred: [],
    canUseReferral: false,
    accessibleCrm: [],
    currentCrm: false,
    hasExecutedAnyAction: true,
    shouldUpdateBillingInformations: false,
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.CONNECTED_TO_API:
            return {
                ...state,
                api_token: action.payload,
            };
        case actions.DISCONNECTED_FROM_API:
            return {
                ...state,
                api_token: false,
            };
        case actions.CONNECTED_TO_LINKEDIN:
            return {
                ...state,
                linkedin_profile: action.payload,
            };
        case queueActions.REST_MODE_UPDATE:
            return {
                ...state,
                rest_mode: action.payload,
            };
        case actions.ACCESSIBLE_CRM:
            return {
                ...state,
                accessibleCrm: action.payload,
            };
        case actions.USER_CONNECTED:
            return {
                ...state,
                currentCrm: {
                    ...state.currentCrm,
                    isOnline: true,
                },
            };
        case actions.USER_DISCONNECTED:
            return {
                ...state,
                currentCrm: {
                    ...state.currentCrm,
                    isOnline: false,
                },
            };
        case 'SHOULD_UPDATE_BILLING_INFORMATION':
            return {
                ...state,
                shouldUpdateBillingInformations: action.payload,
            };
        case 'DELETED_MEMBER':
            return {
                ...state,
                accessibleCrm: state.accessibleCrm.filter((member) => member._id === action.payload.memberId),
            };
        case 'KICKED_FROM_COMPANY':
            return {
                ...state,
                accessibleCrm: [],
            };
        case 'MEMBER_CONNECTED':
            return {
                ...state,
                accessibleCrm: state.accessibleCrm.map((member) => (member._id === action.payload.memberId ? { ...member, isOnline: true } : member)),
            };
        case 'MEMBER_DISCONNECTED':
            return {
                ...state,
                accessibleCrm: state.accessibleCrm.map((member) =>
                    member._id === action.payload.memberId ? { ...member, isOnline: false } : member
                ),
            };
        case actions.ACCESS_CRM:
            return {
                ...state,
                currentCrm: action.payload,
            };
        case actions.FETCHED_PERSONAL_ACTIVITY_REPORT_SETTINGS:
            return {
                ...state,
                activityReport: action.payload,
            };
        case actions.ACCOUNT_TYPE:
            return {
                ...state,
                account_type: action.payload.accountType,
                paymentExpiration: action.payload.paymentExpiration,
                freeTrialExpiration: action.payload.freeTrialExpiration,
                subscriptionType: action.payload.subscriptionType,
            };
        case actions.SET_ORIGIN:
            return {
                ...state,
                origin: action.payload,
            };
        case actions.IS_STAFF:
            return {
                ...state,
                isStaff: action.payload,
            };
        case actions.UPDATE_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case actions.SUBSCRIPTION_ACTIVE:
            return {
                ...state,
                subscriptionActive: action.payload,
            };
        case actions.REFERRAL_LINK:
            return {
                ...state,
                referralLink: action.payload,
            };
        case actions.REFERRED:
            return {
                ...state,
                referred: action.payload,
            };
        case actions.CAN_USE_REFERRAL:
            return {
                ...state,
                canUseReferral: action.payload,
            };
        case actions.HAS_EXECUTED_ACTION:
            return {
                ...state,
                hasExecutedAnyAction: action.payload,
            };
        case 'DELETED_ACTION':
            return {
                ...state,
                hasExecutedAnyAction: true,
            };
        case 'LAST_ACTION_EXECUTED':
            return {
                ...state,
                emptyQueueNotification: true,
            };
        case 'READ_LAST_ACTION_EXECUTED':
            return {
                ...state,
                emptyQueueNotification: false,
            };
        case actions.TUTORIAL_NOTIFICATION:
            return {
                ...state,
                tutorialNotification: action.payload,
            };
        case 'DISCONNECTED_FROM_LINKEDIN':
            return defaultState;
        default:
            return state;
    }
};
