export default {
    "CHANGE_TAB": "CHANGE_TAB",

    "FETCHED_CAMPAIGNS": "FETCHED_CAMPAIGNS",
    "FETCHED_CAMPAIGNS_POPUP": "FETCHED_CAMPAIGNS_POPUP",
    "SELECTED_CAMPAIGN": "SELECTED_CAMPAIGN",

    "UPDATED_CAMPAIGN": "UPDATED_CAMPAIGN",
    "DELETED_CAMPAIGN": "DELETED_CAMPAIGN",
    "CREATED_CAMPAIGN": "CREATED_CAMPAIGN",
    "CREATED_CAMPAIGN_POPUP": "CREATED_CAMPAIGN_POPUP"
}