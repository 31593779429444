import actions from '../../../actions/company.action';

const defaultState = {
    currentTab: 'settings',
    _id: '',
    name: '',
    currentMember: {},
    isOwner: false,
    limitMembers: 0,
    membersCount: 0,
    members: [],
    duplicates: {
        count: 0,
        all: [],
    },
    activityReportSettings: {},
    hasPendingInvite: false,
    canUseEntrepriseFreeTrial: false,
};

const formatDuplicate = (dup) => ({
    ...dup,
    firstName: dup.prospects[0].profile.firstName,
    lastName: dup.prospects[0].profile.lastName,
    profilePicture: dup.prospects[0].profile.profilePicture,
});

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.CHANGE_COMPANY_TAB:
            return {
                ...state,
                currentTab: action.payload.tab,
            };
        case actions.FETCHED_ACTIVITY_REPORT_SETTINGS:
            return {
                ...state,
                activityReportSettings: action.payload,
            };
        case actions.FETCHED_DUPLICATES:
            return {
                ...state,
                duplicates: action.payload,
            };
        case actions.FETCHED_MORE_DUPLICATES:
            return {
                ...state,
                duplicates: {
                    ...state.duplicates,
                    all: [...state.duplicates.all, ...action.payload],
                },
            };
        case actions.FETCHED_MEMBERS:
            return {
                ...state,
                members: action.payload,
            };
        case actions.FETCHED_MORE_MEMBERS:
            return {
                ...state,
                members: [...state.members, ...action.payload],
            };
        case actions.FETCHED_MAX_USERS:
            return {
                ...state,
                limitMembers: action.payload,
            };
        case actions.FETCHED_CURRENT_MEMBER:
            return {
                ...state,
                currentMember: action.payload,
            };
        case actions.UPDATED_MEMBER_PERMISSIONS:
            return {
                ...state,
                members: state.members.map((member) =>
                    member._id === action.payload.memberId ? { ...member, permissions: action.payload.updatedPermissions } : member
                ),
                currentMember:
                    state.currentMember._id === action.payload.memberId
                        ? { ...state.currentMember, permissions: action.payload.updatedPermissions }
                        : state.currentMember,
            };
        case actions.DELETED_MEMBER:
            return action.payload.memberId === state.currentMember._id
                ? defaultState
                : {
                      ...state,
                      members: state.members.filter((member) => member._id !== action.payload.memberId),
                  };
        case actions.KICKED_FROM_COMPANY:
            return defaultState;
        case actions.INVITATION_RECEIVED:
            return {
                ...state,
                hasPendingInvite: true,
                ...(action.payload.company || {}),
            };
        case actions.INVITATION_SENT:
            return {
                ...state,
                members: [...state.members, action.payload.companyMember],
            };
        case actions.INVITATION_DECLINED:
            return {
                ...state,
                members: state.members.filter((member) => member._id !== action.payload.companyMember._id),
            };
        case actions.INVITATION_ACCEPTED:
            return {
                ...state,
                members: state.members.map((member) => (member._id === action.payload.companyMember._id ? action.payload.companyMember : member)),
            };
        case actions.CREATED_DUPLICATE:
            return {
                ...state,
                duplicates: {
                    ...state.duplicates,
                    all: [...state.duplicates.all, formatDuplicate(action.payload.duplicate)],
                    total: state.duplicates.total + 1,
                },
            };
        case actions.DELETED_DUPLICATE:
            return {
                ...state,
                duplicates: {
                    ...state.duplicates,
                    all: state.duplicates.all.filter((duplicate) => duplicate._id !== action.payload.duplicateId),
                    total: state.duplicates.total - 1,
                },
            };
        case actions.UPDATED_DUPLICATE:
            return {
                ...state,
                duplicates: {
                    ...state.duplicates,
                    all: state.duplicates.all.map((duplicate) =>
                        duplicate._id === action.payload.duplicate._id ? formatDuplicate(action.payload.duplicate) : duplicate
                    ),
                },
            };
        case actions.ACCEPT_INVITATION:
            return {
                ...state,
                hasPendingInvite: false,
            };
        case actions.MEMBER_CONNECTED:
            return {
                ...state,
                members: state.members.map((member) => (member._id === action.payload._id ? { ...member, isOnline: true } : member)),
            };
        case actions.MEMBER_DISCONNECTED:
            return {
                ...state,
                members: state.members.map((member) => (member._id === action.payload._id ? { ...member, isOnline: false } : member)),
            };
        case actions.DECLINE_INVITATION:
            return defaultState;
        case actions.FETCHED_COMPANY:
            return {
                ...state,
                ...action.payload,
            };
        case actions.CAN_USE_FREE_TRIAL:
            return {
                ...state,
                canUseEntrepriseFreeTrial: action.payload,
            };
        case 'DISCONNECTED_FROM_LINKEDIN':
            return defaultState;
        default:
            return state;
    }
};
