
const defaultState = {
    tab: "/triggers",
    params: {}
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case "CHANGE_LOCATION":
            return {
                ...state,
                tab: action.payload.tab,
                params: action.payload.params || {}
            };
        case "KICKED_FROM_COMPANY":
            return defaultState
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}