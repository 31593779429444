export const getEnvironment = (state) => state.shared.environment;
export const getRootAccount = (state) => state.shared.accounts.rootAccount;
export const getActiveAccount = (state) =>
  state.shared.accounts.secondaryAccount &&
  state.shared.accounts.secondaryAccount.api_token
    ? state.shared.accounts.secondaryAccount
    : state.shared.accounts.rootAccount;

export const getProgressBar = (state) => state.shared.progressBar;

export const getCrmState = (state) => state.crm;

export const getApiUrl = (state) => getEnvironment(state).api_url;
export const getSocketUrl = (state) => getEnvironment(state).socket_url;
export const isInMaintenance = (state) => getEnvironment(state).maintenance;
export const isLinkedinTabOpen = (state) =>
  getEnvironment(state).isLinkedinTabOpen;
export const shouldHideCommunity = (state) =>
  getEnvironment(state).hideCommunity;

export const isStaff = (state) => getRootAccount(state).isStaff;
export const getRootToken = (state) => getRootAccount(state).api_token;
export const getToken = (state) => getActiveAccount(state).api_token;
export const getRestMode = (state) => getRootAccount(state).rest_mode;
export const isOnAnotherCrm = (state) =>
  !!(
    state.shared.accounts.secondaryAccount &&
    state.shared.accounts.secondaryAccount.api_token
  );
export const getAccessibleCrm = (state) => {
  try {
    const currentMember =
      getCrmState(state).company.shared &&
      getCrmState(state).company.shared.currentMember;
    const rootUser = getRootAccount(state);
    if (
      !rootUser ||
      !currentMember ||
      !currentMember.permissions ||
      !currentMember.permissions.access_crm ||
      !currentMember.permissions.access_crm.members
    )
      return [];
    return [
      ...currentMember.permissions.access_crm.members,
      {
        _id: currentMember._id,
        user: {
          firstName: rootUser.linkedin_profile.firstName,
          lastName: rootUser.linkedin_profile.lastName,
        },
        originalUser: true,
      },
    ];
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const isTheUserLoggedAsConnected = (state) =>
  getRootAccount(state).currentCrm && getRootAccount(state).currentCrm.isOnline;

  
export const getQuest = state => state.crm.quests

export const getAskReview = state => state.crm.review