
import React, { Component } from 'react';
import { TransitionablePortal, Segment } from 'semantic-ui-react'

export default class ErrorDisplayer extends Component {

    render() {
        return <div className='main-page'>
            {this.props.errors || this.props.successes
                ? [...this.props.errors, ...this.props.successes].sort((a, b) => a.time > b.time).map((notif, i) => <TransitionablePortal
                    closeOnTriggerClick
                    transition={{ transition: "fade up", duration: 1000 }}
                    onOpen={this.handleOpen}
                    onClose={() => notif.isError ? this.props.removeError(notif.id) : this.props.removeSuccess(notif.id)}
                    openOnTriggerClick
                    open={true}
                >
                    <Segment style={{ height: "100px", width: "29%", left: '70%', position: 'fixed', top: i * 100 + i * 20, zIndex: 1000 }} color={notif.isError ? "red" : "green"} tertiary inverted>
                        <p style={{ wordBreak: "break-word" }}>{notif.message}</p>
                    </Segment>
                </TransitionablePortal>
                )
                : null}
            {this.props.children}
        </div>
    }
}