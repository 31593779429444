import actions from '../../actions/campaign.action';
import noteActions from '../../actions/note.action';
import messageActions from '../../actions/message.action';
import funnelActions from '../../actions/funnel.action';
import prospectActions from '../../actions/prospect.action';
import environmentActions from '../../actions/environment.action';

const defaultState = {
    all: [],
    selected: {
        tabSelected: 2,
        notes: [],
        messages: [],
        funnels: {
            all: [],
            selected: {}
        },
        prospects: {
            displayed: [],
            selected: [],
            excluded: [],
            total: 0,
            currentPage: 1,
            filters: []
        }
    }
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case environmentActions.DISCONNECTED_FROM:
        case environmentActions.CONNECTED_AS:
            return defaultState;
        case actions.CHANGE_TAB:
            return {
                ...state,
                selected: { ...state.selected, tabSelected: action.payload }
            };
        case actions.FETCHED_CAMPAIGNS:
            return {
                ...state,
                all: action.payload,
                selected: !state.selected._id ? (action.payload[0] ? { ...state.selected, ...action.payload[0] } : {}) : state.selected
            };
        case actions.SELECTED_CAMPAIGN:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.payload,
                    prospects: {
                        ...state.selected.prospects,
                        selected: []
                    }
                }
            };
        case actions.CREATED_CAMPAIGN:
            return {
                ...state,
                all: [...state.all, action.payload],
                selected: { ...defaultState.selected, ...action.payload }
            };
        case actions.DELETED_CAMPAIGN:
            return {
                ...state,
                all: state.all.filter(campaign => campaign._id !== action.payload._id)
            };
        case actions.UPDATED_CAMPAIGN:
            return {
                ...state,
                all: state.all.map(campaign => (campaign._id === action.payload._id ? action.payload : campaign)),
                selected: state.selected._id === action.payload._id ? { ...state.selected, ...action.payload } : state.selected
            };
        case noteActions.FETCHED_NOTE:
            return {
                ...state,
                selected: state.selected._id === action.payload.campaignId ? { ...state.selected, notes: action.payload.notes } : state.selected
            };
        case noteActions.CREATED_NOTE:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? { ...state.selected, notes: [...state.selected.notes, action.payload.entity] }
                        : state.selected
            };
        case noteActions.DELETED_NOTE:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? { ...state.selected, notes: state.selected.notes.filter(note => note._id !== action.payload.entity._id) }
                        : state.selected
            };
        case noteActions.UPDATED_NOTE:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              notes: state.selected.notes.map(note => (note._id === action.payload.entity._id ? action.payload.entity : note))
                          }
                        : state.selected
            };
        case messageActions.FETCHED_MESSAGE:
            return {
                ...state,
                selected: state.selected._id === action.payload.campaignId ? { ...state.selected, messages: action.payload.messages } : state.selected
            };
        case messageActions.CREATED_MESSAGE:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? { ...state.selected, messages: [...state.selected.messages, action.payload.entity] }
                        : state.selected
            };
        case messageActions.DELETED_MESSAGE:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? { ...state.selected, messages: state.selected.messages.filter(message => message._id !== action.payload.entity._id) }
                        : state.selected
            };
        case messageActions.UPDATED_MESSAGE:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              messages: state.selected.messages.map(message =>
                                  message._id === action.payload.entity._id ? action.payload.entity : message
                              )
                          }
                        : state.selected
            };
        case prospectActions.FETCH_PROSPECTS_CAMPAIGN:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              prospects: { ...state.selected.prospects, displayed: action.payload.prospects, total: action.payload.total }
                          }
                        : state.selected
            };
        case prospectActions.SELECTED_PROSPECT_CAMPAIGN:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              prospects: { ...state.selected.prospects, selected: action.payload.selected, excluded: action.payload.excluded }
                          }
                        : state.selected
            };
        case prospectActions.CREATED_PROSPECT:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              prospects: {
                                  ...state.selected.prospects,
                                  displayed:
                                      state.selected.prospects.displayed.length < 100
                                          ? [...state.selected.prospects.displayed, action.payload.entity]
                                          : state.selected.prospects.displayed,
                                  total: state.selected.prospects.total + 1
                              }
                          }
                        : state.selected
            };
        case prospectActions.UPDATED_PROSPECT:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId &&
                    state.selected.prospects.displayed.some(prospect => prospect._id === action.payload.entity._id)
                        ? {
                              ...state.selected,
                              prospects: {
                                  ...state.selected.prospects,
                                  displayed: state.selected.prospects.displayed.some(prospect => prospect._id === action.payload.entity._id)
                                      ? state.selected.prospects.displayed.map(prospect =>
                                            prospect._id === action.payload.entity._id ? { ...prospect, ...action.payload.entity } : prospect
                                        )
                                      : state.selected.prospects.displayed
                              }
                          }
                        : state.selected
            };
        case 'CREATED_TAG':
            console.log(action.payload.entity);
            return {
                ...state,
                selected: {
                    ...state.selected,
                    prospects: {
                        ...state.selected.prospects,
                        filters:
                            state.selected.prospects.filters &&
                            state.selected.prospects.filters.map(filter =>
                                filter.id === 'tags'
                                    ? {
                                          ...filter,
                                          options: [
                                              ...filter.options,
                                              {
                                                  text: action.payload.entity.name,
                                                  isChecked: false,
                                                  key: action.payload.entity._id,
                                                  color: action.payload.entity.color,
                                                  count: 0
                                              }
                                          ]
                                      }
                                    : filter
                            )
                    }
                }
            };
        case 'UPDATED_TAG':
            const tagFilter = {
                text: action.payload.entity.name,
                key: action.payload.entity._id,
                color: action.payload.entity.color
            };
            return {
                ...state,
                selected: {
                    ...state.selected,
                    prospects: {
                        ...state.selected.prospects,
                        displayed: state.selected.prospects.displayed.map(prospect => ({
                            ...prospect,
                            tags: prospect.tags.map(tag => (tag._id === action.payload.entity._id ? { ...tag, ...action.payload.entity } : tag))
                        })),
                        filters:
                            state.selected.prospects.filters &&
                            state.selected.prospects.filters.map(filter =>
                                filter.id === 'tags'
                                    ? {
                                          ...filter,
                                          options: filter.options.map(t => (t.key === action.payload.entity._id ? { ...t, ...tagFilter } : t))
                                      }
                                    : filter
                            )
                    }
                }
            };
        case 'DELETED_TAG':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    prospects: {
                        ...state.selected.prospects,
                        displayed: state.selected.prospects.displayed.map(prospect => ({
                            ...prospect,
                            tags: prospect.tags.filter(tag => tag._id !== action.payload.entity._id)
                        })),
                        filters:
                            state.selected.prospects.filters &&
                            state.selected.prospects.filters.map(filter =>
                                filter.id === 'tags'
                                    ? { ...filter, options: filter.options.filter(t => t.key !== action.payload.entity._id) }
                                    : filter
                            )
                    }
                }
            };
        case prospectActions.DELETED_PROSPECT:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              prospects: {
                                  ...state.selected.prospects,
                                  displayed: state.selected.prospects.displayed.filter(prospect => prospect._id !== action.payload.entity._id),
                                  total: state.selected.prospects.total - 1
                              }
                          }
                        : state.selected
            };
        case prospectActions.MOVED_PROSPECT:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.fromCampaignId
                        ? {
                              ...state.selected,
                              prospects: {
                                  ...state.selected.prospects,
                                  displayed: state.selected.prospects.displayed.filter(prospect => prospect._id !== action.payload.entity._id),
                                  total: state.selected.prospects.total - 1
                              }
                          }
                        : state.selected._id === action.payload.toCampaignId
                        ? {
                              ...state.selected,
                              prospects: {
                                  ...state.selected.prospects,
                                  displayed:
                                      state.selected.prospects.displayed.length < 100
                                          ? [...state.selected.prospects.displayed, action.payload.entity]
                                          : state.selected.prospects.displayed,
                                  total: state.selected.prospects.total + 1
                              }
                          }
                        : state.selected
            };
        case prospectActions.CHANGE_FILTERS:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              prospects: {
                                  ...state.selected.prospects,
                                  selected: [],
                                  filters:
                                      action.payload.filters ||
                                      (state.selected.prospects && state.selected.prospects.filters ? state.selected.prospects.filters : [])
                              }
                          }
                        : state.selected
            };
        case prospectActions.CHANGE_PAGE:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              prospects: {
                                  ...state.selected.prospects,
                                  currentPage: action.payload.activePage
                              }
                          }
                        : state.selected
            };
        case funnelActions.FETCH_FUNNEL:
            return {
                ...state,
                selected: { ...state.selected, funnels: { ...state.selected.funnels, all: action.payload } }
            };
        case funnelActions.SELECT_FUNNEL_CAMPAIGN:
            return {
                ...state,
                selected: { ...state.selected, funnels: { ...state.selected.funnels, selected: action.payload } }
            };
        case funnelActions.FUNNEL_CREATED:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? { ...state.selected, funnels: { ...state.selected.funnels, all: [...state.selected.funnels.all, action.payload.entity] } }
                        : state.selected
            };
        case funnelActions.FUNNEL_PROSPECTS_STOPPED:
            if (state.selected._id === action.payload.campaignId) {
                const toUpdate = action.payload.prospects.filter(id => state.selected.prospects.displayed.some(prospect => id === prospect._id));
                return {
                    ...state,
                    selected: {
                        ...state.selected,
                        prospects: {
                            ...state.selected.prospects,
                            displayed: state.selected.prospects.displayed.map(prospect =>
                                toUpdate.some(id => id === prospect._id) ? { ...prospect, instancedFunnelState: false } : prospect
                            )
                        }
                    }
                };
            }
            return state;
        case funnelActions.FUNNEL_DELETED:
            return {
                ...state,
                selected:
                    state.selected._id === action.payload.campaignId
                        ? {
                              ...state.selected,
                              funnels: {
                                  ...state.selected.funnels,
                                  all: state.selected.funnels.all.filter(funnel => funnel._id !== action.payload.entity._id)
                              }
                          }
                        : state.selected
            };
        case funnelActions.FUNNEL_UPDATED:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    funnels: {
                        selected: state.selected.funnels.selected._id === action.payload._id ? action.payload : state.selected.funnels.selected,
                        all: state.selected.funnels.all.map(funnel => (funnel._id === action.payload._id ? action.payload : funnel))
                    }
                }
            };
        case 'DISCONNECTED_FROM_LINKEDIN':
            return defaultState;
        default:
            return state;
    }
};
