import prospectActions from '../../actions/prospect.action'

const defaultState = {
    all: [],
    selected: {},
    total: 0
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case "CHANGE_LOCATION":
            return action.payload.tab !== "/prospect"
                ? defaultState
                : state
        case prospectActions.FETCH_PROSPECTS_PAGE:
            return {
                ...state,
                all: action.payload.prospects,
                total: action.payload.count
            };
        case prospectActions.FETCH_SUBSEQUENT_PROSPECTS_PAGE:
            return {
                ...state,
                all: [...state.all, ...action.payload.prospects],
                total: action.payload.count
            };
        case prospectActions.SELECT_PROSPECT_PAGE:
            return {
                ...state,
                selected: action.payload
            };
        case prospectActions.DELETED_PROSPECT:
            return {
                ...state,
                all: state.all.filter(prospect => prospect._id !== action.payload.entity._id)
            };
        case prospectActions.UPDATED_PROSPECT:
            return {
                ...state,
                all: state.all.map(prospect => prospect._id === action.payload.entity._id
                    ? { ...prospect, ...action.payload.entity }
                    : prospect)
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}