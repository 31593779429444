import actions from "../actions/progressbar.action"

const defaultState = {
    all: []
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.CREATE_PROGRESS_BAR:
            return {
                ...state,
                all: [...state.all, action.payload.progressBar]
            }
        case actions.UPDATE_PROGRESS_BAR:
            return {
                ...state,
                all: state.all.map(progress => progress.id === action.payload.progressBar.id
                    ? { ...progress, ...action.payload.progressBar }
                    : progress
                )
            }
        case actions.REMOVE_PROGRESS_BAR:
            return {
                ...state,
                all: state.all.filter(progress => progress.id !== action.payload.progressBar.id)
            }
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}