import actions from '../../actions/overview.action'
import campaignActions from '../../actions/campaign.action'
import queueActions from '../../actions/queue.action'
import prospectAction from '../../actions/prospect.action';
import funnelAction from '../../actions/funnel.action';

const defaultState = {
    campaigns: 0,
    queue: 0,
    prospects: 0,
    hasFunnel: false
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.FETCH_OVERVIEW:
            return {
                ...state,
                ...action.payload
            };
        case campaignActions.CREATED_CAMPAIGN:
            return {
                ...state,
                campaigns: state.campaigns + 1
            };
        case campaignActions.DELETED_CAMPAIGN:
            return {
                ...state,
                campaigns: state.campaigns - 1 < 0 ? 0 : state.campaigns - 1
            };
        case queueActions.CREATED_ACTION:
            return {
                ...state,
                queue: action.payload.hasOwnProperty("count")
                    ? state.queue + action.payload.count
                    : state.queue + 1
            };
        case queueActions.DELETED_ACTION:
            return {
                ...state,
                queue: action.payload.hasOwnProperty("count")
                    ? state.queue - action.payload.count
                    : state.queue - 1
            };
        case prospectAction.CREATED_PROSPECT:
            return {
                ...state,
                prospects: state.prospects + 1
            };
        case prospectAction.DELETED_PROSPECT:
            return {
                ...state,
                prospects: action.payload.hasOwnProperty("count")
                    ? state.prospects - action.payload.count
                    : state.prospects - 1
            };
        case queueActions.DELETED_ALL_ACTION:
            return {
                ...state,
                queue: 0
            };
        case funnelAction.FUNNEL_CREATED:
            return {
                ...state,
                hasFunnel: true
            };
        case funnelAction.FUNNEL_PROSPECT_ADDED:
            return {
                ...state,
                prospectsInFunnel: state.prospectsInFunnel || state.prospectsInFunnel === 0 ? state.prospectsInFunnel + 1 : 0
            };
        case funnelAction.FUNNEL_PROSPECT_STOPPED:
        case funnelAction.FUNNEL_PROSPECT_REPLIED:
            return {
                ...state,
                prospectsInFunnel: state.prospectsInFunnel ? state.prospectsInFunnel - 1 : 0
            };
        case funnelAction.FUNNEL_PROSPECTS_COUNT:
            return {
                ...state,
                prospectsInFunnel: action.payload.count
            };
        case "DELETED_TRIGGER":
            return {
                ...state,
                triggers: state.triggers ? state.triggers - 1 : 0
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}