import NotificationDisplayer from '../screens/NotificationDisplayer'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import NotificationAction from '../redux/actions/notification.action'
import { getCrmState } from "../redux/selectors"

const mapStateToProps = state => {
    state = getCrmState(state)
    return {
        errors: state.notifications.errors,
        successes: state.notifications.successes
    }
};

const mapDispatchToProps = dispatch => {
    return {
        async removeError(id) {
            dispatch({ type: NotificationAction.REMOVE_ERROR, payload: id })
        },
        async removeSuccess(id) {
            dispatch({ type: NotificationAction.REMOVE_SUCCESS, payload: id })
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(NotificationDisplayer));