import actions from "../../actions/quests.action";
import moment from "moment";

const defaultState = {
  quest: {
    name: "",
    missions: [],
    trialType: "",
    active: true,
    previousQuest: {},
  },
  progression: {
    user: "",
    quest: "",
    startAt: null,
    endAt: null,
    redeem: false,
    missions: [],
  },
};

export default (
  state = defaultState,
  action = { type: null, payload: null }
) => {
  switch (action.type) {
    case actions.FETCH_QUEST:
      return {
        ...state,
        quest: action.payload.quest,
        progression: action.payload.progression,
      };
    case actions.START_QUEST:
      return {
        ...state,
        quest: action.payload,
        progression: {
          ...state.progression,
          missions: [],
          quest: action.payload._id,
          endAt: null,
          redeem: false,
        },
      };
    case actions.QUEST_MISSION_COMPLETE:
      return {
        ...state,
        progression: {
          ...state.progression,
          missions: state.progression.missions.includes(
            action.payload.missionId
          )
            ? state.progression.missions
            : [...state.progression.missions, action.payload.missionId],
        },
      };
    case actions.QUEST_COMPLETE:
      return {
        ...state,
        progression: {
          ...state.progression,
          endAt: moment(),
        },
      };
    default:
      return state;
  }
};
