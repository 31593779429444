import actions from "../../actions/dashboard.action"
import campaignActions from "../../actions/campaign.action"

const defaultState = {
    generalStats: {
        current: {
            connected: 0,
            pending: 0,
            post: 0,
            views: 0
        },
        last: {
            connected: 0,
            post: 0,
            views: 0
        }
    },
    activityData: {
        tab: 0,
        selectedCampaign: { _id: "all" },
        selectedNote: "all",
        selectedMessage: "all",
        selectedFormat: "day_by_day",
        currentPage: 0,
        totalPages: 1,
        plotData: {}
    },
    performances: {
        selectedCampaign: { _id: "all" },
        selectedTimeFrame: 7,
        data: {}
    }
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.GENERAL_STATS:
            return {
                ...state,
                generalStats: {
                    current: { ...state.generalStats.current, ...action.payload.current },
                    last: { ...state.generalStats.last, ...action.payload.last }
                },
            };
        case actions.ACTIVITY_SELECTED_CAMPAIGN:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    selectedCampaign: action.payload
                }
            };
        case actions.ACTIVITY_SELECTED_NOTE:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    selectedNote: action.payload
                }
            };
        case actions.ACTIVITY_SELECTED_MESSAGE:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    selectedMessage: action.payload
                }
            };
        case actions.ACTIVITY_TAB:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    tab: action.payload
                }
            };
        case actions.ACTIVITY_SELECTED_FORMAT:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    selectedFormat: action.payload
                }
            };
        case actions.ACTIVITY_PLOT_DATA:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    plotData: action.payload
                }
            };
        case actions.ACTIVITY_TOTAL_PAGES:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    totalPages: action.payload
                }
            };
        case actions.ACTIVITY_CHANGE_PAGE:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    currentPage: action.payload
                }
            };
        case actions.ACTIVITY_FETCHED_NOTE:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    selectedCampaign: action.payload.campaignId === state.activityData.selectedCampaign._id
                        ? { ...state.activityData.selectedCampaign, notes: action.payload.notes }
                        : state.activityData.selectedCampaign
                }
            };
        case actions.ACTIVITY_FETCHED_MESSAGE:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    selectedCampaign: action.payload.campaignId === state.activityData.selectedCampaign._id
                        ? { ...state.activityData.selectedCampaign, messages: action.payload.messages }
                        : state.activityData.selectedCampaign
                }
            };
        case actions.PERFORMANCES_DATA:
            return {
                ...state,
                performances: { ...state.performances, data: action.payload }
            };
        case actions.PERFORMANCE_SELECTED_CAMPAIGN:
            return {
                ...state,
                performances: { ...state.performances, selectedCampaign: action.payload }
            };
        case actions.PERFORMANCE_SELECTED_TIMEFRAME:
            return {
                ...state,
                performances: { ...state.performances, selectedTimeFrame: action.payload }
            };
        case campaignActions.DELETED_CAMPAIGN:
            return {
                ...state,
                activityData: {
                    ...state.activityData,
                    selectedCampaign: state.activityData.selectedCampaign._id === action.payload._id ? { _id: "all" } : state.activityData.selectedCampaign
                },
                performances: {
                    ...state.performances,
                    selectedCampaign: state.performances.selectedCampaign._id === action.payload._id ? { _id: "all" } : state.performances.selectedCampaign
                }
            }
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}