import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Dimmer, Input, Loader, Modal } from "semantic-ui-react";
import UserServices from "../../../services/UserServices";

const LoginScreen = (props) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [emailModal, setEmailModal] = useState(false);
  return (
    <div style={{ height: "100vh", display: "flex", width: "100%" }}>
      <Dimmer active inverted>
        <Dimmer active={loading} inverted>
          <Loader size="massive" inverted disabled={!loading} />
        </Dimmer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ color: "black" }}>{t("password_required_title")}</h1>
          <Input
            error={error}
            onChange={(e) => {
              setPassword(e.target.value);
              setError("");
            }}
            style={{ marginBottom: 20 }}
            type="password"
            value={password}
          />
          <Button
            primary
            onClick={async () => {
              setLoading(true);
              try {
                const result = await UserServices.loginWithPassword(password);
                dispatch({
                  type: "CONNECTED_TO_API",
                  payload: result.token,
                });
                dispatch({
                  type: "IS_PASSWORD_AUTHENTICATED",
                  payload: true,
                });
              } catch (e) {
                setError(t("login_failed"));
              }
              setLoading(false);
            }}
          >
            {t("login")}
          </Button>
          <a
            style={{ cursor: "pointer", marginTop: 5 }}
            onClick={() => {
              setLoading(true);
              UserServices.passwordForgot()
                .then(() => setEmailModal(true))
                .finally(() => setLoading(false));
            }}
          >
            {t("password_forgotten")}
          </a>
          <Modal onClose={() => setEmailModal(false)} open={emailModal}>
            <Modal.Header>{t("password_forgotten_title")}</Modal.Header>
            <Modal.Content
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p>{t("password_forgotten_email_sent")}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                onClick={() => {
                  setEmailModal(false);
                }}
              >
                {t("validate")}
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </Dimmer>
      <div
        style={{
          position: "absolute",
          background: `url(background.jpeg) no-repeat center center fixed`,
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          filter: "blur(1.5rem)",
        }}
      />
    </div>
  );
};

export default LoginScreen;
