import actions from "../../actions/layout.action"

const defaultState = {
    'all': []
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.FETCH_LAYOUTS:
            return {
                ...state,
                all: action.payload || []
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}