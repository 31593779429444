import actions from "../../../actions/company.action"

const defaultState = {
    events: []
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.FETCHED_FEED:
            return {
                ...state,
                events: [...state.events, ...action.payload]
            }
        case actions.RESET_FEED:
            return {
                ...state,
                events: []
            }
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}