import plugin from '@analytics/google-analytics';

const CONFIG = {
  trackingId: 'G-178HNCZKTB',
};

export const gaPlugin = plugin(CONFIG);
export const config = {
  name: 'google-analytics',
};
