/* global chrome */
import { track } from "../utils/analytics";
import { EXTENSION_ID } from "../constants";

export const setupMessageListener = () => {
  const handler = (request, sender, sendResponse) => {
    (async () => {
      if (request.type === "FIRST_PAYMENT_CONVERSION") {
        track("firstPaymentMade", request.payload);
      } else if (request.type === "UPSELL_PAYMENT_CONVERSION") {
        track("paymentUpsellMade", request.payload);
      }
    })();
    return true;
  };

  const initPort = (port) => {
    port.onMessage.addListener(handler);
    console.log(`new port connected "${port.name}"`);
    return port;
  };

  let port = initPort(
    chrome.runtime.connect(EXTENSION_ID, {
      name: 'redux',
    }),
  );	
};

