import actions from "../../../actions/trigger.action"
import moment from "moment"

const defaultState = {
    formData: {
        name: "",
        inputs: [],
        conditions: [],
        outputs: []
    }
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.UPDATE_TRIGGER_FORM_DATA:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    ...action.payload
                }
            }
        default:
            return state
    }
}