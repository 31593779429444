import actions from "../../../actions/funnel.action"

const defaultState = {
    schema: {},
    states: {
        isCombined: false,
        data: []
    }
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.SET_SCHEMA:
            return {
                ...state,
                schema: action.payload
            };
        case actions.SET_STATES:
            return {
                ...state,
                states: action.payload
            };
        case actions.FUNNEL_PROSPECT_STOPPED:
            return {
                ...state,
                states: {
                    ...state.states,
                    data: state.states.data.map(state => state._id === action.payload.entity ? { ...state, currentStep: undefined, stepLabel: "stopped", status: { text: "stopped" } } : state)
                }
            };
        case actions.FUNNEL_PROSPECT_REPLIED:
            return {
                ...state,
                states: {
                    ...state.states,
                    data: state.states.data.map(state => state._id === action.payload.entity ? { ...state, currentStep: undefined, hasReplied: true, status: { text: "funnel_replied" } } : state)
                }
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}