import axios from "axios";
import { apiUrl, rootTokenHeader, tokenHeader } from "../helpers/ApiHelper";

export default {
  async fetchQuota() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/quota`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async fetchOverview() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/overview`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getAccountType() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?accountType=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getShouldUpdateBillingInformation() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(
        `${apiUrl()}/me?shouldUpdateBillingInformation=true`,
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async canUseEntrepriseFreeTrial() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?companyHistory=true`, header);
      return (
        res.data &&
        res.data.companyHistory &&
        res.data.companyHistory.length === 0
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async startFreeTrialEnterprise() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.post(`${apiUrl()}/company`, {}, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getCompanyMember(useRootToken) {
    try {
      const header = useRootToken ? rootTokenHeader() : tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?companyMember=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getCompany() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?company=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async canUseReferral() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?canUseReferral=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async changeLanguage(language) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(
        `${apiUrl()}/me?language=true`,
        { language },
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getBills() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/invoices`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getEmail() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?email=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getSubscriptionActive() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(
        `${apiUrl()}/me?subscriptionActive=true`,
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getUrlUpdateBillingIdentity() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(
        `${apiUrl()}/me/urlUpdateBillingIdentity`,
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateEmail(email) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(`${apiUrl()}/me`, { email }, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getBillingInformation() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/billing`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateBillingInformation(body) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(`${apiUrl()}/me/billing`, body, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getSignature(body) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.post(`${apiUrl()}/me/upgrade`, body, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async upgradeV2(plan, discountCode, places) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.post(
        `${apiUrl()}/me/payment`,
        { plan, discountCode, places },
        header
      );
      return res.data && res.data.url;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getBill(body) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.post(`${apiUrl()}/me/bill`, body, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async checkCode(code, plan) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(
        `${apiUrl()}/me/discount?code=${code}&plan=${plan}`,
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getLastFunnelUpdate() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(
        `${apiUrl()}/me?lastFunnelUpdate=true`,
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getReferralLink() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?referralLink=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getReferred() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?referred=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async unsubscribe() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/stripe`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async loginWithPassword(password) {
    try {
      const header = rootTokenHeader();
      if (!header) return false;
      const res = await axios.get(
        `${apiUrl()}/me/authenticate?password=${password}`,
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async passwordForgot() {
    try {
      const header = rootTokenHeader();
      if (!header) return false;
      const res = await axios.put(`${apiUrl()}/me/forgetPassword`, {}, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async loginAsStaff(email) {
    try {
      const header = rootTokenHeader();
      if (!header) return false;
      const res = await axios.get(
        `${apiUrl()}/staff/loginAsUser?email=${email}`,
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updatePassword(password, passwordConfirm) {
    try {
      const header = rootTokenHeader();
      if (!header) return false;
      const res = await axios.put(
        `${apiUrl()}/me/password`,
        { password, passwordConfirm },
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getOverview() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/overview`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getActivityReportSettings() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?activityReport=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateActivityReportSettings(data) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(
        `${apiUrl()}/me`,
        { activityReport: data },
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getQuotasRange() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?quota=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateQuotaRange(message, follow, connect, visit) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(
        `${apiUrl()}/me`,
        { message, follow, connect, visit },
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async resetWarming() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(
        `${apiUrl()}/me`,
        { warming: ["connect"] },
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async isWarming() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?warming=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getCompanyPaymentVariables(validCode, selectedPeriod, value) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(
        `${apiUrl()}/me/payment?places=${value}&periodicity=${selectedPeriod}${
          validCode ? `&discountCode=${validCode}` : ""
        }`,
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getProducts() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/products`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async pay(productId) {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.post(
        `${apiUrl()}/me/transaction`,
        { productId },
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getQuest() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/quests`, header);
      return res.data;
    } catch (error) {
      if (error.response.status === 404) {
        return {};
      }
      console.log(error);
      throw error;
    }
  },
  async getNbQuest() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/quests/list`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getRewardQuest() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me/quests/redeem`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async setCommunityQuest() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(
        `${apiUrl()}/me/quests/community`,
        {},
        header
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async setSupportQuest() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(`${apiUrl()}/me/quests/support`, {}, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getTestUser() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?experiences=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async disableAskReview() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.delete(`${apiUrl()}/me/askReview `, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async delayAskReview() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.put(`${apiUrl()}/me/askReview`, {}, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async startQuest() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.post(`${apiUrl()}/me/quests/start`, {}, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getUserCanStartQuest() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?canStartQuest=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getUserWaapiId() {
    try {
      const header = tokenHeader();
      if (!header) return false;
      const res = await axios.get(`${apiUrl()}/me?waapiIdClient=true`, header);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
