import { Analytics } from 'analytics';
import { gtmPlugin, config as GTMPluginConfig } from './GTM';
import { gaPlugin, config as GAPluginConfig } from './GA';
import { segmentPlugin, config as SegmentPluginConfig } from './Segment';
import Package from '../../../package.json';

const mergedConfigs = [
  GTMPluginConfig,
  GAPluginConfig,
  SegmentPluginConfig,
].reduce((acc, val) => {
  if (!(val.name in acc)) {
    acc[val.name] = val;
  }
  return acc;
}, {});


function extractConfigProperties(key) {
  return Object.values(mergedConfigs).reduce((acc, val) => {
    if (key in val && typeof val[key] !== 'undefined') {
      acc[val.name] = val[key];
    }
    return acc;
  }, {});
}


export const analytics = Analytics({
  app: 'prospectin',
  version: Package.version,
  plugins: [segmentPlugin, gtmPlugin, gaPlugin],
});

analytics.ready((...args) => {
  console.log('plugin loaded');
});

export const identify = (
  clientId,
  customData,
  all = true,
) => {
  analytics.identify(clientId, customData, {
    all,
    ...extractConfigProperties('identify'),
  });
};

export const track = (name, properties, all = true) => {
  analytics.track(name, properties, {
    all,
    ...extractConfigProperties('track'),
  });
};

export const page = (data, all = true) => {
  analytics.page(data, { all, ...extractConfigProperties('page') });
};

export const getUser = () => {
  return analytics.user;
};
