export default {
    FETCH_FUNNEL: 'FETCH_FUNNEL',
    FETCH_FUNNEL_FOR_SCREEN: 'FETCH_FUNNEL_FOR_SCREEN',
    IS_LOADING_INSTANCE_PICKER: 'IS_LOADING_INSTANCE_PICKER',
    SET_KEY_NUMBERS: 'SET_KEY_NUMBERS',
    SET_SCHEMA: 'SET_SCHEMA',
    SET_STATES: 'SET_STATES',
    FUNNEL_SELECT_CAMPAIGN: 'FUNNEL_SELECT_CAMPAIGN',
    FUNNEL_SELECT_FUNNEL: 'FUNNEL_SELECT_FUNNEL',
    FUNNEL_SELECT_INSTANCE: 'FUNNEL_SELECT_INSTANCE',
    FUNNEL_CREATED: 'FUNNEL_CREATED',
    SELECT_FUNNEL_CAMPAIGN: 'SELECT_FUNNEL_CAMPAIGN',
    FUNNEL_UPDATED: 'FUNNEL_UPDATED',
    FUNNEL_PROSPECT_STOPPED: 'FUNNEL_PROSPECT_STOPPED',
    FUNNEL_PROSPECT_ADDED: 'FUNNEL_PROSPECT_ADDED',
    FUNNEL_PROSPECTS_COUNT: 'FUNNEL_PROSPECTS_COUNT',
    FUNNEL_PROSPECT_REPLIED: 'FUNNEL_PROSPECT_REPLIED',
    FUNNEL_PROSPECTS_STOPPED: 'FUNNEL_PROSPECTS_STOPPED',
    FUNNEL_DELETED: 'FUNNEL_DELETED',
    UPDATED_INSTANCE_FUNNEL: 'UPDATED_INSTANCE_FUNNEL'
};
