import actions from '../../actions/campaign.action'

const defaultState = {
    all: []
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.FETCHED_CAMPAIGNS_POPUP:
            return {
                ...state,
                all: action.payload
            };
        case actions.CREATED_CAMPAIGN_POPUP:
            return {
                ...state,
                all: [...state.all, action.payload]
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}