import actions from "../../../actions/trigger.action"
import moment from "moment"

const defaultState = {
    selected: "all",
    triggers: [
        {
            "triggerType": "post_linkedin",
            "name": "My trigger",
            "isActivated": true,
            "dateNextExecution": moment(),
            "history": {
                "success": 5,
                "failed": 3,
                "total": 15,
                "failedNotSeen": 10
            },
            "_id": "string",
            "actionType": "move_to_another_campaign",
            "lastUpdateDate": moment().add(12, "hours")
        }
    ],
    triggerErrorFeed: {
        total: 0,
        errors: []
    }
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.UPDATE_TRIGGER_TABLE_DATA:
            return {
                ...state,
                ...action.payload
            }
        case actions.UPDATED_TRIGGER:
            return {
                ...state,
                triggers: state.triggers.map(trigger => trigger._id === action.payload.entity._id
                    ? { ...trigger, ...action.payload.entity }
                    : trigger)
            }
        case actions.CREATED_TRIGGER:
            return {
                ...state,
                triggers: [...state.triggers, action.payload.entity]
            }
        case actions.DELETED_TRIGGER:
            return {
                ...state,
                triggers: state.triggers.filter(trigger => trigger._id !== action.payload.entity._id)
            }
        case actions.ERRORS_SEEN:
            return {
                ...state,
                triggers: state.triggers.map(trigger => trigger._id === action.payload.triggerId
                    ? { ...trigger, history: { ...trigger.history, failedNotSeen: 0 } }
                    : trigger)
            }
        case actions.FETCHED_ERRORS:
            return {
                ...state,
                triggerErrorFeed: action.payload
            }
        case actions.FETCHED_SUBSEQUENT_ERRORS:
            return {
                ...state,
                triggerErrorFeed: {
                    ...state.triggerErrorFeed,
                    total: action.payload.total,
                    errors: [...state.triggerErrorFeed.errors, ...action.payload.errors]
                }
            }
        default:
            return state
    }
}