import plugin from '@analytics/google-tag-manager';

const CONFIG = {
  containerId: 'GTM-WV27MMM',
};

export const gtmPlugin = plugin(CONFIG);
export const config = {
  name: 'google-tag-manager',
};
