export default {
    "CONNECTED_TO_LINKEDIN": "CONNECTED_TO_LINKEDIN",
    "DISCONNECTED_FROM_LINKEDIN": "DISCONNECTED_FROM_LINKEDIN",
    "CONNECTED_TO_API": "CONNECTED_TO_API",
    "DISCONNECTED_FROM_API": "DISCONNECTED_FROM_API",
    "MISSING_EXTENSION": "MISSING_EXTENSION",
    "MISSING_EXTENSION_INSTALLED": "MISSING_EXTENSION_INSTALLED",
    "IS_LINKEDIN_TAB_OPEN": "IS_LINKEDIN_TAB_OPEN",
    "UPDATE_LANGUAGE": "UPDATE_LANGUAGE",
    "ACCOUNT_TYPE": "ACCOUNT_TYPE",
    "IS_REFERRED": "IS_REFERRED",
    "REFERRAL_LINK": "REFERRAL_LINK",
    "CAN_USE_REFERRAL": "CAN_USE_REFERRAL",
    "REFERRED": "REFERRED",
    "IS_STAFF": "IS_STAFF",
    "ACCOUNTS_NUMBER": "ACCOUNTS_NUMBER",
    "SET_ORIGIN": "SET_ORIGIN",
    "IS_DELETING_PENDING": "IS_DELETING_PENDING",
    "MAINTENANCE": "MAINTENANCE",
    "SUBSCRIPTION_ACTIVE": "SUBSCRIPTION_ACTIVE",
    "CONNECTED_AS": "CONNECTED_AS",
    "DISCONNECTED_FROM": "DISCONNECTED_FROM",
    "EMAIL": "EMAIL",
    "ACCESSIBLE_CRM": "ACCESSIBLE_CRM",
    "ACCESS_CRM": "ACCESS_CRM",
    "SECONDARY_ACCOUNT_TYPE": "SECONDARY_ACCOUNT_TYPE",
    "USER_DISCONNECTED": "USER_DISCONNECTED",
    "USER_CONNECTED": "USER_CONNECTED",
    "HAS_EXECUTED_ACTION": "HAS_EXECUTED_ACTION",
    "FETCHED_PERSONAL_ACTIVITY_REPORT_SETTINGS": "FETCHED_PERSONAL_ACTIVITY_REPORT_SETTINGS",
    "TUTORIAL_NOTIFICATION": "TUTORIAL_NOTIFICATION"
}