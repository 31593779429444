import actions from '../../actions/queue.action'

const defaultState = {
    queued: [],
    selected: [],
    total: 0,
    quota: {},
    countByType: {
        visit: 0,
        connect: 0,
        message: 0,
        follow: 0
    }
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.FETCH_QUEUE:
            return {
                ...state,
                queued: action.payload.queued,
                total: action.payload.total
            };
        case actions.FETCH_SUBSEQUENT_QUEUE:
            return {
                ...state,
                queued: [...state.queued, ...action.payload.queued]
            };
        case actions.DELETED_ACTION:
            return action.payload.hasOwnProperty("count")
                ? state
                : {
                    ...state,
                    queued: state.queued ? state.queued.filter(act => act._id !== action.payload) : state.queued,
                    total: state.total - 1
                };
        case actions.SELECTED_ACTIONS:
            return {
                ...state,
                selected: action.payload
            };
        case actions.DELETED_ALL_ACTION:
            return {
                ...state,
                queued: [],
                selected: [],
                total: 0
            };
        case actions.FETCHED_QUOTA:
            return {
                ...state,
                quota: action.payload
            };
        case actions.INCREMENT_QUOTA:
            return {
                ...state,
                quota: state.quota[action.payload]
                    ? { ...state.quota, [action.payload]: { ...state[action.payload], quota: state.quota[action.payload].quota + 1 } }
                    : state.quota
            };
        case actions.DECREMENT_QUOTA:
            return {
                ...state,
                quota: state.quota[action.payload]
                    ? { ...state.quota, [action.payload]: { ...state[action.payload], quota: state.quota[action.payload].quota - 1 } }
                    : state.quota,
                countByType: state.countByType[action.payload]
                    ? { ...state.countByType, [action.payload]: state.countByType[action.payload] - 1 }
                    : state.countByType
            };
        case actions.FETCH_ACTION_BY_TYPE_COUNT:
            return {
                ...state,
                countByType: action.payload
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}