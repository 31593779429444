export default {
    "CREATED_PROSPECT": "CREATED_PROSPECT",
    "UPDATED_PROSPECT": "UPDATED_PROSPECT",
    "DELETED_PROSPECT": "DELETED_PROSPECT",

    "SELECTED_PROSPECT_CAMPAIGN": "SELECTED_PROSPECT_CAMPAIGN",
    "FETCH_PROSPECTS_CAMPAIGN": "FETCH_PROSPECTS_CAMPAIGN",
    "CHANGE_FILTERS": "CHANGE_FILTERS",
    "CHANGE_PAGE": "CHANGE_PAGE",

    "FETCH_PROSPECTS_PAGE": "FETCH_PROSPECTS_PAGE",
    "SELECT_PROSPECT_PAGE": "SELECT_PROSPECT_PAGE",
    "FETCH_SUBSEQUENT_PROSPECTS_PAGE": "FETCH_SUBSEQUENT_PROSPECTS_PAGE",
    "FETCH_PROSPECT_COUNT_PAGE": "FETCH_PROSPECT_COUNT_PAGE",

    "MOVED_PROSPECT": "MOVED_PROSPECT"
}