import actions from "../../../actions/funnel.action"

const defaultState = {
    stats: {
        connectAccepted: 0,
        connectSent: 0,
        currentProspects: 0,
        followBack: 0,
        followed: 0,
        messagesReceived: 0,
        messagesSeen: 0,
        messagesSent: 0,
        outProspects: 0,
        visited: 0
    }
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.SET_KEY_NUMBERS:
            return {
                ...state,
                stats: { ...state.stats, ...action.payload }
            };
        case actions.FUNNEL_PROSPECT_STOPPED:
        case actions.FUNNEL_PROSPECT_REPLIED:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    currentProspects: state.stats.currentProspects - 1,
                    outProspects: state.stats.outProspects + 1
                }
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}