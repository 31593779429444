import { combineReducers } from 'redux';
import crm from './CRM';
import popup from './Popup'
import environment from './environment.reducer';
import progressBar from './progressbar.reducer';
import Accounts from './Accounts';

const shared = () => combineReducers({
    environment,
    progressBar,
    accounts: Accounts()
});

export default () => combineReducers({
    shared: shared(),
    crm: crm(),
    popup: popup()
});