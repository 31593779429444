import actions from '../../../actions/funnel.action';
import campaignActions from '../../../actions/campaign.action';

const defaultState = {
    isLoading: false,
    funnels: [],
    instances: [],
    selectedCampaign: {},
    selectedFunnel: {},
    selectedInstance: {}
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.FETCH_FUNNEL_FOR_SCREEN:
            return {
                ...state,
                funnels: action.payload
            };
        case actions.FUNNEL_SELECT_CAMPAIGN:
            return {
                ...state,
                selectedCampaign: action.payload
            };
        case campaignActions.DELETED_CAMPAIGN:
            return {
                ...state,
                selectedCampaign: state.selectedCampaign._id === action.payload._id ? {} : state.selectedCampaign
            };
        case actions.FUNNEL_SELECT_FUNNEL:
            return {
                ...state,
                selectedFunnel: action.payload
            };
        case actions.FUNNEL_SELECT_INSTANCE:
            return {
                ...state,
                selectedInstance: action.payload
            };
        case actions.FUNNEL_DELETED:
            if (state.selectedCampaign._id !== action.payload.campaignId) return state;
            const newFunnels = state.funnels.filter(funnel => funnel._id !== action.payload.entity._id);
            return {
                ...state,
                funnels: newFunnels,
                selectedFunnel: newFunnels[0] || {}
            };
        case actions.IS_LOADING_INSTANCE_PICKER:
            return {
                ...state,
                isLoading: action.payload
            };
        case actions.UPDATED_INSTANCE_FUNNEL:
            return {
                ...state,
                instances: state.instances.map(instance =>
                    instance._id === action.payload._id
                        ? {
                              ...instance,
                              ...action.payload,
                              states: state.selectedInstance.states.map(st => ({
                                  ...st,
                                  ...(action.payload.pause
                                      ? { currentStepWaiting: action.payload.pause, currentStep: null }
                                      : { currentStepWaiting: action.payload.pause })
                              }))
                          }
                        : instance
                ),
                selectedInstance:
                    state.selectedInstance._id === action.payload._id
                        ? {
                              ...state.selectedInstance,
                              ...action.payload,
                              states: state.selectedInstance.states.map(st => ({
                                  ...st,
                                  ...(action.payload.pause
                                      ? { currentStepWaiting: action.payload.pause, currentStep: null }
                                      : { currentStepWaiting: action.payload.pause })
                              }))
                          }
                        : state.selectedInstance
            };
        case 'DISCONNECTED_FROM_LINKEDIN':
            return defaultState;
        default:
            return state;
    }
};
