import actions from '../../actions/notification.action'

const defaultState = {
    errors: [],
    successes: []
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.NEW_ERROR:
            return {
                ...state,
                errors: [...(state.errors ? state.errors : []), action.payload]
            };
        case actions.REMOVE_ERROR:
            return {
                ...state,
                errors: state.errors.filter(error => error.id !== action.payload)
            };
        case actions.NEW_SUCCESS:
            return {
                ...state,
                errors: [...(state.successes ? state.successes : []), action.payload]
            };
        case actions.REMOVE_SUCCESS:
            return {
                ...state,
                errors: state.successes.filter(error => error.id !== action.payload)
            };
        case "DISCONNECTED_FROM_LINKEDIN":
            return defaultState;
        default:
            return state
    }
}