/* global chrome */
import store from '../redux/configureStore'
import { getToken, getApiUrl, getRootToken } from "../redux/selectors"

export const tokenHeader = () => ({
    headers: {
        'Authorization': `Bearer ${getToken(store.entity.getState())}`,
        'x-client-version': chrome && chrome.runtime && chrome.runtime.getManifest && chrome.runtime.getManifest().version ? chrome.runtime.getManifest().version : 'x.x.x'
    }
})

export const rootTokenHeader = () => ({
    headers: {
        'Authorization': `Bearer ${getRootToken(store.entity.getState())}`,
        'x-client-version': chrome && chrome.runtime && chrome.runtime.getManifest && chrome.runtime.getManifest().version ? chrome.runtime.getManifest().version : 'x.x.x'
    }
})

export const apiUrl = () => getApiUrl(store.entity.getState())