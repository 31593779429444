import actions from '../../actions/review.action'

const defaultState = {
    reviewToAsk: false,
};

export default (state = defaultState, action = { type: null, payload: null }) => {
    switch (action.type) {
        case actions.ASK_FOR_REVIEW:
            return {
                ...state,
                reviewToAsk: true
            };
        default:
            return state
    }
}